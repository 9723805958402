import React, { useEffect, useState } from 'react';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import axios from 'axios';
import { Box, Container, AppBar, Toolbar, Typography, IconButton, Popover, Slider, Switch, ThemeProvider, createTheme, Tooltip } from '@mui/material';
import SettingsIcon from '@mui/icons-material/Settings';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import './App.css';


function App() {
  const [data, setData] = useState([]);
  const [maxWidth, setMaxWidth] = useState("xxl");
  const [anchorEl, setAnchorEl] = useState(null);
  const [darkMode, setDarkMode] = useState(false);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get('http://localhost:10975/players/');
        const rawData = response.data.map(item => ({
          ...item,
          isDraftedMe: false,
          isDraftedOther: false
        }));
        const sortedData = [...rawData].sort((a, b) => b['Normalized Projected Points'] - a['Normalized Projected Points']);
        const rankedData = sortedData.map((item, index) => ({
          ...item,
          index: index + 1
        }));
  
        setData(rankedData);
      } catch (error) {
        console.error('Error fetching the data', error);
      }
    };

    fetchData();
  }, []);

  const columns = [
    { field: 'index', headerName: '#', width: 70 },
    { field: 'Name', headerName: 'Name', width: 150 },
    { field: 'position', headerName: 'Position', width: 90 },
    { field: 'currentAge', headerName: 'Age', width: 90 },
    { field: 'team', headerName: 'Team', width: 170 },
    { field: 'games', headerName: 'Games', type: 'number', width: 90 },
    { field: 'goals', headerName: 'Goals', type: 'number', width: 90 },
    { field: 'assists', headerName: 'Assists', type: 'number', width: 90 },
    { field: 'points', headerName: 'Points', type: 'number', width: 90 },
    { field: 'Fantasy Points', headerName: 'Fantasy Points', type: 'number', width: 150 },
    { field: 'Projected Points', headerName: 'Projected Points', type: 'number', width: 150 },
    // { field: 'Projected Points w Age Curve', headerName: 'Projected Points w Age Curve', type: 'number', width: 250 },
    { field: 'Normalized Projected Points', headerName: 'Normalized Projected Points', type: 'number', width: 250 },
    {
      field: 'draftStatus',
      headerName: 'Draft Status',
      width: 100,
      renderCell: (params) => (
        <>
          <Tooltip title="Drafted By Me">
            <IconButton onClick={() => handleDraftMeClick(params, params.id)}>
              {params.row.isDraftedMe ? <CheckIcon /> : <CloseIcon />}
            </IconButton>
          </Tooltip>
          <Tooltip title="Drafted By Others">
            <IconButton onClick={() => handleDraftOtherClick(params, params.id)}>
              {params.row.isDraftedOther ? <CheckIcon /> : <CloseIcon />}
            </IconButton>
          </Tooltip>
        </>
      )
    }
  ];

  const handleOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleSliderChange = (event, newValue) => {
    const maxWidthValues = ['xs', 'sm', 'md', 'lg', 'xl', 'false'];
    setMaxWidth(maxWidthValues[newValue]);
  };

  const handleDarkModeToggle = () => {
    setDarkMode(!darkMode);
  };

  const handleDraftMeClick = (params, id) => {
    const updatedData = data.map(item => 
      item.id === id ? {...item, isDraftedMe: !item.isDraftedMe} : item
    );
    setData(updatedData);
  };
  
  const handleDraftOtherClick = (params, id) => {
    const updatedData = data.map(item => 
      item.id === id ? {...item, isDraftedOther: !item.isDraftedOther} : item
    );
    setData(updatedData);
  };

  const theme = createTheme({
    palette: {
      mode: darkMode ? 'dark' : 'light',
      primary: {
        main: darkMode ? '#90caf9' : '#1976d2',
      },
      background: {
        default: darkMode ? '#303030' : '#fafafa',
      },
    },
  });

  const getRowClassName = (params) => {
    if (params.row.isDraftedMe) {
      return 'drafted-me';
    }
    if (params.row.isDraftedOther) {
      return 'drafted-other';
    }
    return '';
  };

  return (
    <ThemeProvider theme={theme}>
      <Box className={darkMode ? 'dark-mode' : 'light-mode'} style={{ height: '100vh', backgroundColor: theme.palette.background.default }}>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" style={{ flexGrow: 1 }}>Braden's Fantasy Draft Board</Typography>
          <IconButton color="inherit" onClick={handleOpen}>
            <SettingsIcon />
          </IconButton>
          <Popover
            open={Boolean(anchorEl)}
            anchorEl={anchorEl}
            onClose={handleClose}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'right',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            PaperProps={{
              style: {
                width: '400px'
              },
            }}
          >
            <Box display="flex" alignItems="center" padding={2} marginTop={3}>
              <Typography gutterBottom>Table Size:</Typography>
              <Box width="50%" marginLeft={2}>
              <Slider
                defaultValue={100}
                step={20} 
                min={0}
                max={100}
                valueLabelDisplay="auto"
                valueLabelFormat={value => `${value}%`}
                onChange={(event, newValue) => handleSliderChange(event, Math.round(newValue / 20))}
              />
              </Box>
            </Box>
            <Box display="flex" alignItems="center" padding={2}>
              <Typography gutterBottom>Dark Mode:</Typography>
              <Switch checked={darkMode} onChange={handleDarkModeToggle} />
            </Box>
          </Popover>
        </Toolbar>
      </AppBar>
      <Container maxWidth={maxWidth} style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box style={{ height: 1200, width: '100%', maxWidth: '2000px', marginTop: '20px' }}>
          <DataGrid 
            rows={data} 
            columns={columns} 
            pageSize={10} 
            slots={{
              toolbar: GridToolbar,
            }}
            slotProps={{
              columnsPanel: {
                disableHideAllButton: true,
                disableShowAllButton: true,
              },
            }}
            className={darkMode ? 'dark-grid' : 'light-grid'}
            getRowClassName={getRowClassName}
          />
        </Box>
      </Container>

      </Box>
    </ThemeProvider>
  );
}

export default App;
